import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRoutes } from '@/entities/navigation';
import { useCurrentUserNavigation, useMultiI18nKeys } from '@/entities/user';
import { Text } from '@/shared/ui/text';

import { NavigationLink } from '../navigation-link';
import * as Styled from './navigation-item.styles';
import { NavigationItemProps } from './navigation-item.types';

const NavigationItemComponent = ({ routes, title, dynamicTitle, collapsed, close }: NavigationItemProps) => {
	const { t } = useTranslation('common');
	const { role, isPlant } = useCurrentUserNavigation();
	const dynamicRoutes = useRoutes();
	const keys = useMultiI18nKeys();
	const label = dynamicTitle?.(isPlant) || title;

	return (
		<>
			{!collapsed && (
				<Text label={t(label)} weight={600} color="invertedPrimary" align={collapsed ? 'center' : 'left'} />
			)}
			<Styled.NavigationWrapper direction="column" gap={16} tag="nav" collapsed={collapsed}>
				{routes
					.filter(({ canShow }) => (canShow ? canShow(role, isPlant) : true))
					.map(({ dynamicHref, dynamicLabel, dynamicSvg, href, label, Svg }) => {
						const route = dynamicHref?.(dynamicRoutes) ?? href;
						const i18nKey = dynamicLabel?.(keys) || label;
						const svg = dynamicSvg?.(isPlant) || Svg;

						return (
							<NavigationLink
								key={i18nKey}
								close={close}
								label={i18nKey}
								collapsed={collapsed}
								href={route}
								Svg={svg}
							/>
						);
					})}
			</Styled.NavigationWrapper>
		</>
	);
};

export default memo(NavigationItemComponent);
