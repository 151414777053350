export enum AppStorage {
	Token = 'token',
	AccessToken = 'access token',
	Timezone = 'timezone',
	Sidebar = 'sidebar',
	User = 'user',
	Boat = 'boat',
	ItemCount = 'itemCount',
	FirstAccess = 'is first access',
}
