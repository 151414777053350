import { CombinedState, configureStore, DeepPartial, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';

import { boatReducer } from '@/entities/boat';
import { historicalDataReducer, historicalTableReducer } from '@/entities/historical-data';
import { modalReducer } from '@/entities/modal';
import { notificationsReducer } from '@/entities/notifications';
import { toastReducer } from '@/entities/toast';
import { userReducer } from '@/entities/user';
import { virtualCockpitReducer } from '@/entities/virtual-cockpit';
import { boatServiceApi } from '@/shared/api/boat-service.api';
import { dashboardServiceApi } from '@/shared/api/dashboard-service.api';
import { hlabServiceApi } from '@/shared/api/hlab-service.api';
import { userServiceApi } from '@/shared/api/user-service.api';

import { rtkQueryErrorLogger } from './error-logger';
import { createReducerManager } from './reducer-manager';
import { StoreSchema, StoreWithReducerManager } from './store.schema';

const saveSubsetFilter = createFilter('boat', ['selectedId']);

const persistConfig = {
	key: 'boat',
	storage,
	whitelist: ['boat'],
	transforms: [saveSubsetFilter],
};

export function createStore(
	preloadedState?: DeepPartial<StoreSchema>,
	lazyReducers?: DeepPartial<ReducersMapObject<StoreSchema>>,
	hasPersistor?: boolean,
): StoreWithReducerManager {
	const rootReducers: ReducersMapObject<StoreSchema> = {
		...lazyReducers,
		user: userReducer,
		toast: toastReducer,
		modal: modalReducer,
		boat: boatReducer,
		historicalData: historicalDataReducer,
		notifications: notificationsReducer,
		virtualCockpit: virtualCockpitReducer,
		historicalTable: historicalTableReducer,
		[userServiceApi.reducerPath]: userServiceApi.reducer,
		[boatServiceApi.reducerPath]: boatServiceApi.reducer,
		[dashboardServiceApi.reducerPath]: dashboardServiceApi.reducer,
		[hlabServiceApi.reducerPath]: hlabServiceApi.reducer,
	};

	const reducerManager = createReducerManager(rootReducers);

	const persistedReducer = persistReducer(persistConfig, reducerManager.reduce as Reducer<CombinedState<StoreSchema>>);

	const store = configureStore({
		reducer: hasPersistor ? persistedReducer : reducerManager.reduce,
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({ serializableCheck: false })
				.concat(rtkQueryErrorLogger)
				.concat(userServiceApi.middleware)
				.concat(boatServiceApi.middleware)
				.concat(dashboardServiceApi.middleware)
				.concat(hlabServiceApi.middleware),
		devTools: IS_DEV,
	});

	const persistor = persistStore(store);

	return { ...store, persistor, reducerManager };
}
